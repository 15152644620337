import React from 'react'
import { Layout, Input, Menu, Button, Tag, Popover } from 'antd'
import { QuestionCircleOutlined, UserOutlined, FlagOutlined, LogoutOutlined, SolutionOutlined, InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { messageTypes } from '../../actions/messages'
import settings from '../../config/'
import CtfHeaderClock from '../../components/common/ctfHeaderClock'
import CertificateDropdown from './certificateDropdown'
import StackActionDropdown from './stackActionDropdown'
import VpnDropdown from './vpnDropdown'
import StackSwitchDropdown from '../../components/common/stackSwitchDropdown'
import HDComponent from '../../components/common/hdComponent'

const { Header } = Layout
const SubMenu = Menu.SubMenu
const MenuItemGroup = Menu.ItemGroup

class StyledHeader extends HDComponent {
  searchRef = React.createRef()

  componentDidUpdate (prevProps, prevState) {
    // when stack switched, clear search input
    if (prevProps.stack === 'switching' && typeof this.props.stack === 'object' && this.searchRef?.current) {
      this.searchRef.current.setValue('')
    }
  }

  render () {
    const {
      status, language, uiPathname, auth, stackActionStatus, handleCADownload,
      user, stack, stackVpns, userStacks, isUserRankingOnly/*, machinesPowerOnAll, machinesPowerOffAll */
    } = this.props

    const stackTitle = stack !== 'switching' && stack && stack.name
    const statusOff = stack && stackActionStatus === undefined && (stack.status === 'poweredoff' || stack.status === 'suspended')
    const statusOn = stack && stackActionStatus === undefined && stack.status === 'poweredon'

    const statusTag = (
      <Tag
        onClick={() => !stack?.disable_steerings ? (statusOff ? this.props.executeStackAction(stack._id, 'poweron') : (statusOn ? this.props.executeStackAction(stack._id, 'suspend') : null)) : null}
        style={{ marginLeft: '10px' }}
        className={'stack-action' + (status !== 'reverting' ? (statusOff ? ' stack-action-off' : (statusOn ? ' stack-action-on' : '')) : '') + (stack?.disable_steerings ? ' stack-action-disabled' : '')}
        title={!stack?.disable_steerings ? (statusOff ? messageTypes[language].wakeup_stack : (statusOn ? messageTypes[language].suspend_stack : null)) : ''}
      >
        {['reverting', 'poweringon', 'suspending', 'poweringoff', 'testing', 'waiting-for-revert', 'waiting-for-poweron', 'waiting-for-suspend'].includes(status) ? <><LoadingOutlined /> </> : ''}{messageTypes[language]['stack_status_' + status]}
      </Tag>
    )

    return (
      <>
        <Header style={{ background: '#fff', padding: 0 }}>
          <Menu mode='horizontal' selectable={false} style={{ lineHeight: '64px', textAlign: 'right' }}>
            {['/', '/machines', '/scenarios', '/tests', '/ranking', '/challenges'].includes(uiPathname) &&
              auth && userStacks && ((userStacks.length === 1 && stack?.nr !== userStacks[0].nr) || userStacks.length > 1) &&
              (
                <Menu.Item className='menu-top-left'><StackSwitchDropdown language={language} /></Menu.Item>
              )}

            {(uiPathname === '/' || uiPathname === '/machines') && stack && stack.ca_certificates && !!stack.ca_certificates.length &&
              (
                <Menu.Item className='menu-top-left'>
                  <CertificateDropdown language={language} handleCADownload={handleCADownload} certificates={stack.ca_certificates} />
                </Menu.Item>
              )}

            {(uiPathname === '/' || uiPathname === '/machines') && auth && stackVpns && stackVpns.length > 0 &&
              <Menu.Item className='menu-top-left'><VpnDropdown language={language} /></Menu.Item>}

            {(uiPathname === '/machines') && auth && stack && stack.revert &&
              [
                <Menu.Item className='menu-top-left' key='setup_new_env'>
                  <StackActionDropdown
                    disabled={status !== 'poweredon' && status !== 'poweredoff' && status !== 'suspended' && status !== 'failed'}
                    executeStackAction={this.props.executeStackAction}
                    language={language}
                    stack={stack}
                  />
                </Menu.Item>
              /* Temporary hide power on/off all machines
              <Menu.Item className="menu-top-left" key="power_on_all_machines">
                <Button onClick={() => { this.props.handlePowerOnAllMachines() }}>
                  <FontAwesomeIcon name='power-off' spin={machinesPowerOnAll || false} />{ messageTypes[language]['power_on_all_machines'] }
                </Button>
              </Menu.Item>,
              <Menu.Item className="menu-top-left" key="power_off_all_machines">
                <Button onClick={() => { this.props.handlePowerOffAllMachines() }}>
                  <FontAwesomeIcon name='power-off' spin={machinesPowerOffAll || false} />{ messageTypes[language]['power_off_all_machines'] }
                </Button>
              </Menu.Item>
              */
              ]}

            {auth && (uiPathname === '/scenarios' || uiPathname === '/machines' || uiPathname === '/settings/logs' || uiPathname === '/tests') &&
              <Menu.Item>
                <Input
                  onChange={(e) => this.props.handleInputChange(e)}
                  ref={this.searchRef}
                  placeholder={uiPathname === '/settings/logs' ? messageTypes[language].table_search_desc : messageTypes[language].search}
                />
              </Menu.Item>}

            {(user && user.team_token) &&
              <Menu.Item
                className='team-token'
                onClick={() => { this.props.copyToClipboard(user.team_token, language) }}
                title={messageTypes[language].click_to_copy}
              >
                {messageTypes[language].your_team_token}: {user.team_token}
              </Menu.Item>}

            {(stack && stack.desc && stack.desc[language]) &&
              <Menu.Item>
                <Button type='primary' onClick={() => { this.props.handleQuickGuideVisibility(true) }} className='quick-guide-button'>
                  <QuestionCircleOutlined />{messageTypes[language].quick_guide}
                </Button>
              </Menu.Item>}

            {settings.instruction &&
              <Menu.Item>
                <Button type='primary' onClick={() => { this.props.handleInstructionVisibility(true) }} className='instruction-button'>
                  <InfoCircleOutlined />{messageTypes[language].instruction}
                </Button>
              </Menu.Item>}

            {settings.external_link &&
              <Menu.Item>
                <Button type='primary' className='external-button'>
                  <a href={settings.external_link.url} target='_blank' rel='noopener noreferrer'>
                    {settings.external_link.icon && (
                      <span role='img' aria-label='info-circle' class='anticon anticon-info-circle'>
                        <img className='external-link-icon' src={settings.external_link.icon} alt='link icon' />
                      </span>)}
                    {settings.external_link.text}
                  </a>
                </Button>
              </Menu.Item>}

            {
              auth &&
                <SubMenu title={
                  <span className='submenu-title-wrapper'>
                    <UserOutlined />{user && user.name}
                  </span>
                }
                >
                  <MenuItemGroup className='user-settings-submenu'>
                    {!isUserRankingOnly &&
                      <Menu.Item>
                        <p onClick={() => { this.props.handleProfileVisibility(true) }}>
                          {messageTypes[language].profile} <SolutionOutlined />
                        </p>
                      </Menu.Item>}
                    <Menu.Item>
                      <p onClick={() => { this.props.handleLogout() }}>
                        {messageTypes[language].logout} <LogoutOutlined />
                      </p>
                    </Menu.Item>
                  </MenuItemGroup>
                </SubMenu>
            }

            <SubMenu title={
              <span className='submenu-title-wrapper'>
                <FlagOutlined />{messageTypes[language].long_name}
              </span>
            }
            >
              <MenuItemGroup>
                {
                  this.languages.map((val, key) =>
                    <Menu.Item key={key}>
                      <p onClick={() => { this.props.handleClick(val) }}>
                        {messageTypes[val].long_name}
                      </p>
                    </Menu.Item>
                  )
                }
              </MenuItemGroup>
            </SubMenu>
          </Menu>
        </Header>

        {(uiPathname === '/' || uiPathname === '/machines' || uiPathname === '/scenarios' || uiPathname === '/tests' || uiPathname === '/challenges' || uiPathname === '/ranking') && auth && stack && stackTitle &&
          <>
            <h2 className='stack-title'>
              <span>
                {stack?.stack_title_image &&
                  <img className='logo-stack-title' src={'/cp_images/' + stack.type + '_' + stack.content_pack_version + '_' + stack?.stack_title_image} alt='logo' />}
                {stackTitle}
              </span>
              {settings.overlord_enabled && stack.status &&
                (
                  <>
                    {!stack?.disable_steerings && statusOff && <Button className='stack-action-button-map' onClick={() => this.props.executeStackAction(stack._id, 'poweron')}>{messageTypes[language].wakeup_stack}</Button>}
                    {!stack?.disable_steerings && statusOn && <Button className='stack-action-button-map' onClick={() => this.props.executeStackAction(stack._id, 'suspend')}>{messageTypes[language].suspend_stack}</Button>}
                    {!stack?.disable_steerings && ((status === 'failed' && <Popover content={messageTypes[language].stack_status_failed_explain} placement='bottom'>{statusTag}</Popover>) || statusTag)}
                  </>
                )}
            </h2>
          </>}

        {(uiPathname === '/' || uiPathname === '/challenges' || uiPathname === '/ranking') && stack && stack.is_ctf && <CtfHeaderClock className='ctf-clock-header' />}
      </>
    )
  }
}

export default StyledHeader
